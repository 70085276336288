import React, { ReactElement } from 'react';
import { classNameArrayToString } from '@mydse/design-system';

interface IOwnProps {
  hidden: boolean;
  children: ReactElement[];
}

type Props = IOwnProps;

const TabContent = ({ hidden, children }: Props): ReactElement => {
  const classNameString = classNameArrayToString([
    'tabContent',
    hidden && 'hidden'
  ]);

  return <div className={classNameString}>{children}</div>;
};

export default TabContent;
