import { BehaviorSubject, concat, forkJoin, Observable, of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, map, take, tap } from 'rxjs/operators';
import {
  CurrentUser,
  SsoProvider,
  TokenResponse
} from '@mydse/typings';
import {
  RequestOptions,
  tokenService
} from '@mydse/design-system';
import { notificationService } from '@mydse/react-ui';
import {
  authenticationService,
  companyService,
  Company,
  Credentials,
  permissionService,
  systemsService,
  UserAccountData,
  UserAccountType,
  i18n,
  consentService,
  streamService,
  productService,
  UserService
} from '@services';
import { userApiService } from '@services/api';
import { errorResponseInterceptor } from '@services/api/abstract/interceptors';

const error$ = new BehaviorSubject<null | number>(null);
const isInitialized$ = new BehaviorSubject<boolean>(false);
const user$ = new BehaviorSubject<null | CurrentUser>(null);
const isLoggedIn$ = new BehaviorSubject<boolean>(false);

systemsService.getCurrentTime();

const updateTimeZone = (value: string): Observable<CurrentUser> => userApiService
  .updateTimeZone(value)
  .pipe(tap((user: CurrentUser) => {
    setUser(user, false);
    i18n.changeLanguage(user.preferredLanguage);
  }));

const detectTimeZone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone;

const updateLanguage = (value: string): Observable<CurrentUser> => userApiService.updateLanguage(value)
  .pipe(tap((user: CurrentUser) => {
    setUser(user, false);
    i18n.changeLanguage(user.preferredLanguage);
  }));

const checkUserData = (preferredLanguage?: string, timeZone?: string, isImpersonatedBy: boolean = false): void => {
  if (preferredLanguage && !isImpersonatedBy) {
    i18n.changeLanguage(preferredLanguage);
  }
  const timeZoneObservable = timeZone
    ? of()
    : updateTimeZone(detectTimeZone());
  const preferredLanguageObservable = preferredLanguage
    ? of()
    : updateLanguage(i18n.language);
  concat(timeZoneObservable, preferredLanguageObservable)
    .subscribe(); // Serial requests is used to prevent DB simultaneously transactions conflict in user update (mydse/proliance-360-teams/team1#286)
};

const onUserLogin = (user: CurrentUser): void => {
  const { analyticsAllowed, preferredLanguage, timeZone, impersonatedBy } = user;
  const isImpersonatedBy = !!impersonatedBy;
  checkUserData(preferredLanguage, timeZone, !!impersonatedBy);
  if (analyticsAllowed !== false && !isImpersonatedBy) {
    consentService.initialize(user);
  }
};

const setUser = (user: null | CurrentUser, updateConsent: boolean = true): null | CurrentUser => {
  const isLogin = !user$.value;
  user$.next(user);
  isLoggedIn$.next(user !== null);
  if (user === null) {
    tokenService.resetToken();
  } else if (isLogin) {
    onUserLogin(user);
  } else if (!!user && !user.impersonatedBy && updateConsent) {
    consentService.setUser(user);
  }
  isInitialized$.next(true);
  return user;
};

function getErrorhandler<T>(returnValue: T): ((error: AjaxError) => Observable<T>) {
  return (error: AjaxError): Observable<T> => {
    if (!error$.value || error.status > error$.value) {
      error$.next(error.status);
    }
    return of(returnValue);
  };
}

const checkUser = (): Promise<boolean> => {
  const token = tokenService.getToken();
  return new Promise((resolve: (value: boolean) => void): void => {
    if (token !== null) {
      const userRequest: Observable<null | CurrentUser> = userApiService.getCurrentUser(token, [ 500, 502 ])
        .pipe(catchError(getErrorhandler(null)));
      const companyRequest: Observable<null | Company> = companyService.updateCurrentCompany([ 500, 502 ])
        .pipe(catchError(getErrorhandler(null)));
      const permissionRequest: Observable<void> = permissionService.assignPermissionData([ 500, 502 ])
        .pipe(catchError(getErrorhandler(undefined)));
      const productRequest: Observable<void> = productService.getProductsData([ 500, 502 ])
        .pipe(catchError(getErrorhandler(undefined)));
      forkJoin([
        userRequest,
        companyRequest,
        permissionRequest,
        productRequest
      ])
        .pipe(
          take(1)
        )
        .subscribe((data: [ null | CurrentUser, null | Company, void, void ]): void => {
          const [ user ] = data;
          if (error$.value === null) {
            setUser(user);
            error$.next(null);
          } else {
            errorResponseInterceptor({ status: error$.value } as AjaxError);
            isInitialized$.next(true);
          }
          resolve(!!user);
        });
    } else {
      setUser(null);
      resolve(false);
    }
  });
};

const login = (credentials: Credentials): Observable<null | TokenResponse> => {
  tokenService.resetToken();
  return authenticationService
    .login(credentials)
    .pipe(
      catchError(() => of(null))
    );
};

const ssoLogin = (ssoProvider: SsoProvider, parameters: Record<string, string>): Observable<null | TokenResponse> => {
  tokenService.resetToken();
  return authenticationService
    .ssoLogin(ssoProvider, parameters)
    .pipe(
      catchError(() => of(null))
    );
};

const getUserData = (accessToken?: string): Observable<null | CurrentUser> => {
  if (typeof accessToken === 'undefined') {
    setUser(null);
    return of(null);
  }
  tokenService.setToken(accessToken);
  return forkJoin([
    userApiService.getCurrentUser(accessToken),
    companyService.updateCurrentCompany(),
    permissionService.assignPermissionData(),
    productService.getProductsData()
  ])
    .pipe(
      take(1),
      map((data: [ null | CurrentUser, null | Company, void, void ]) => {
        const [ user, company ] = data;
        if (company) {
          return setUser(user);
        } else {
          const dataAttributesDictionary = {
            test: { 'notificationError': 'noCompany' },
            guide: { 'notificationError': 'noCompany' }
          };
          notificationService.error({
            namespaces: 'login',
            textTranslationKey: 'login.noCompany',
            dataAttributesDictionary
          });
          return setUser(null);
        }
      }),
      catchError(() => of(null))
    );
};

const updateUser = (): Observable<null | CurrentUser> => {
  const token = tokenService.getToken();
  return token === null
    ? of(setUser(null))
    : userApiService
      .getCurrentUser(token)
      .pipe(
        take(1),
        tap((user: null | CurrentUser) => setUser(user))
      );
};

const updateUserData = (body: Partial<CurrentUser>): Observable<CurrentUser> => userApiService
  .updateCurrentUser(body)
  .pipe(tap((user: CurrentUser) => setUser(user)));

const clearUserData = (): void => {
  setUser(null);
  companyService.resetCompany();
  permissionService.resetPermissionData();
};

const logout = (): Observable<null> => {
  const token = tokenService.getToken();
  streamService.unsubscribe();
  clearUserData();
  if (typeof (window as any).pendo?.setGuidesDisabled !== 'undefined'
    && typeof (window as any).pendo?.isReady !== 'undefined'
    && (window as any).pendo.isReady()
  ) {
    consentService.setGuidesDisabled(false);
  }
  if (token !== null) {
    streamService.unsubscribe();
    return authenticationService
      .logout(token);
  }
  return of(null);
};

const updateTwoFactorAuthorization = (value: boolean): Observable<CurrentUser> => userApiService
  .setTwoFactorAuthorization(value)
  .pipe(map((user: CurrentUser) => setUser(user, false)!));

const updateAnalyticsAllowed = (value: boolean, options?: RequestOptions): Observable<CurrentUser> => userApiService
  .updateAnalyticsAllowed(value, options)
  .pipe(map((user: CurrentUser) => setUser(user, false)!));

const resetPassword = (email: string): Observable<string | AjaxError> => userApiService
  .resetPassword(email)
  .pipe(
    tap(() => {
      const dataAttributesDictionary = {
        test: { 'notificationWarning': 'resetSuccess' },
        guide: { 'notificationWarning': 'resetSuccess' }
      };
      notificationService.warn({
        namespaces: 'reset',
        textTranslationKey: 'success',
        options: { autoClose: false },
        dataAttributesDictionary
      });
    }),
    catchError((error: AjaxError) => {
      switch (error.status) {
        case 400:
          const dataAttributesDictionary = {
            test: { 'notificationError': 'reset400' },
            guide: { 'notificationError': 'reset400' }
          };
          notificationService.error({
            namespaces: 'notification',
            textTranslationKey: 'reset.400', // TODO: Add translation
            dataAttributesDictionary
          });
          break;
      }
      return of(error);
    })
  );
const setPassword = (token: string, password: string): Observable<null> => userApiService
  .setPassword(token, password);
const getAccountActivation = (token: string): Observable<UserAccountType> => userApiService
  .getAccountActivation(token);
const setAccountActivation = (user: UserAccountData): Observable<UserAccountType | AjaxError> => userApiService.setAccountActivation(user);

export const userService: UserService = {
  isInitialized$,
  error$,
  user$,
  isLoggedIn$,
  checkUser,
  login,
  ssoLogin,
  getUserData,
  updateUser,
  updateUserData,
  clearUserData,
  logout,

  updateTwoFactorAuthorization,
  updateTimeZone,
  updateLanguage,
  updateAnalyticsAllowed,
  setPassword,
  resetPassword,

  getAccountActivation,
  setAccountActivation,
  getEmailIntervalData: userApiService.getEmailIntervalData.bind(userApiService),
  updateEmailIntervalData: userApiService.updateEmailIntervalData.bind(userApiService)
};
