import { RouteListType } from '@router/configuration';
import { ComponentPermissionData } from '@mydse/typings';
import { getDynamicRoutePermission, routerDependencies } from '@router';
import { permissionService } from '@services';

export const getRoutePermission = (routeName: RouteListType): ComponentPermissionData => {
  const dependency = routerDependencies[routeName];
  if (typeof dependency === 'undefined') {
    return permissionService.getComponentsPermission(routeName);
  }
  if (typeof dependency.resolve === 'undefined' || !Object.keys(dependency.resolve).length) {
    return permissionService.getComponentsPermission(routeName);
  }
  const { dynamic } = dependency;
  return dynamic
    ? getDynamicRoutePermission(Object.keys(dependency.resolve) as RouteListType[])
    : permissionService.getComponentsPermission(routeName);
};
