import React, { FC } from 'react';
import { FieldError } from 'react-hook-form/dist/types/errors';
import { useTranslation } from 'react-i18next';
import { classNameArrayToString } from '@mydse/design-system';

import styles from './PasswordValidator.styl';

interface Rule {
  rule: string;
  text: string;
}

interface Props {
  passwordExists: boolean;
  error?: FieldError;
}

export const PasswordValidator: FC<Props> = ({ passwordExists, error }) => {
  const { t } = useTranslation('form');
  const errorList = Object.keys(error?.types || {});
  const ruleList: Rule[] = [
    { rule: 'symbol', text: t('passwordRules.symbol') },
    { rule: 'digit', text: t('passwordRules.digit') },
    { rule: 'uppercase', text: t('passwordRules.upperCase') },
    { rule: 'lowercase', text: t('passwordRules.lowerCase') },
    { rule: 'min', text: t('passwordRules.length') },
    { rule: 'space', text: t('passwordRules.noSymbol') }
  ];

  const getRuleStyle = (rule: string) => {
    return errorList.includes(rule)
      ? styles.ruleFail
      : styles.rulePass;
  };

  const ruleClassName = (rule: string) => classNameArrayToString([
    styles.rule,
    ...(passwordExists || error?.type ? [ getRuleStyle(rule) ] : [])
  ]);

  const listItem = (
    <ul className={ styles.ruleList }>
      {
        ruleList.map((item) => {
          return <li
            key={ item.rule }
            className={ ruleClassName(item.rule) }
          >
            { item.text }
          </li>;
        })
      }
    </ul>
  );

  return (
    <div className={ styles.ruleWrap }>
      <p className={ styles.ruleText }>
        { t('passwordRules.text') }
      </p>
      { listItem }
    </div>
  );
};
