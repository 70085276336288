import { storageService } from '@mydse/design-system';
import { redirectUrnStorageName } from '@router';

export const removeStorageRedirectData = (key: string): void => {
  const storageData = storageService.get<Record<string, string>>(redirectUrnStorageName);
  if (storageData !== null) {
    delete storageData[key];
    storageService.set(redirectUrnStorageName, storageData);
  }
};
