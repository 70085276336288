import { ActivationFn, ActivationFnFactory, State } from 'router5';
import { productService } from '@services';
import { productRedirect } from '@router';
import { ProductEnum } from '@mydse/typings';

export const selectProductMiddleware: ActivationFnFactory = (): ActivationFn => (toState: State): boolean | Promise<boolean> => {
  const { name, params } = toState;
  if (name !== 'products') {
    return true;
  }
  const singleInternalProductName = productService.getSingleInternalProductName();
  if (singleInternalProductName) {
    return productRedirect({ product: ProductEnum[singleInternalProductName], parameters: params });
  }
  return true;
};
