import { TableState } from 'react-table';
import { Observable } from 'rxjs';
import { CollectionParameters, Domain } from '@mydse/typings';
import { defaultPageSize } from '@mydse/design-system';
import { ITableParameters } from '@interfaces';
import {
  AssignCompanyManagementData,
  CompanyBusiness,
  CompanyData,
  CompanyManagement,
  CompanyManagementData,
  companyService,
  EditInviteCompanyManagementData,
  getRequestParametersFromTableParameters,
  OrganizationService
} from '@services';
import { domainApiService, organizationApiService } from '@services/api';

export const domainInitialState = {
  pageIndex: 0,
  pageSize: defaultPageSize,
  sortBy: [{
    id: 'domain',
    desc: false
  }]
} as Partial<TableState<Domain>>;

export const domainInitialParameters: CollectionParameters = {
  page: 1,
  pageSize: defaultPageSize,
  sortField: 'domain',
  sortDirection: 'asc'
};

const getCompanyBasicData = (): Observable<CompanyData> =>  organizationApiService
  .getCompanyBasicData();

const updateCompanyBasicData = (data: CompanyData): Observable<CompanyData> => organizationApiService
  .updateCompanyBasicData(data);

const getCompanyManagement = (): Observable<CompanyManagement[]> => organizationApiService
  .getCompanyManagement();

const assignManagementPerson = (
  data: AssignCompanyManagementData
): Observable<CompanyManagement> => organizationApiService
  .assignManagementPerson(data);

const updateManagementPerson = (
  userId: number,
  data: AssignCompanyManagementData | EditInviteCompanyManagementData
): Observable<CompanyManagement> => organizationApiService
  .updateManagementPerson(userId, data);

const inviteManagementPerson = (
  data: CompanyManagementData
): Observable<CompanyManagement> => organizationApiService
  .inviteManagementPerson(data);

const unAssignManagementPerson = (itemId: number): Observable<null> => organizationApiService
  .unAssignManagementPerson(itemId);

const getCompanyBusinessDetails = (): Observable<CompanyBusiness> => organizationApiService
  .getCompanyBusinessDetails();

const updateCompanyBusinessDetails = (body: CompanyBusiness): Observable<CompanyBusiness> => organizationApiService
  .updateCompanyBusinessDetails(body);

const getDomainCollection = (parameters?: Partial<ITableParameters<Domain>>) => domainApiService
    .getDomainCollection({ ...getRequestParametersFromTableParameters(parameters), companyId: companyService.company$.value!.id });

export const organizationService: OrganizationService = {
  getCompanyBasicData,
  updateCompanyBasicData,
  getCompanyManagement,
  assignManagementPerson,
  updateManagementPerson,
  inviteManagementPerson,
  unAssignManagementPerson,
  getCompanyBusinessDetails,
  updateCompanyBusinessDetails,
  getDomainCollection,
  addDomain: domainApiService.addDomain.bind(domainApiService),
  updateDomain: domainApiService.updateDomain.bind(domainApiService),
  deleteDomain: domainApiService.deleteDomain.bind(domainApiService)
};
