import { BackupService } from '@services';
import { storageService } from '@mydse/design-system';

const storageKey = 'backup';
const setBackup = (key: string, value: {}): void => {
  const backupData = storageService.get<Record<string, {}>>(storageKey) || {};
  storageService.set(storageKey, {...backupData, [key]: value });
};
const getBackup = (key: string): object => {
  const backupData = storageService.get<Record<string, {}>>('backup') || {};
  return typeof backupData[key] === 'undefined' ? {} : backupData[key];
};
const removeBackup = (key: string): void => {
  const backupData = storageService.get<Record<string, {}>>('backup') || {};
  if (typeof backupData[key] !== 'undefined') {
    delete backupData[key];
    storageService.set(key, backupData);
  }
};

export const backupService: BackupService = {
  getBackup,
  setBackup,
  removeBackup
};
