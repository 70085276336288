import { ActivationFn, ActivationFnFactory, State } from 'router5';
import { notificationService } from '@mydse/react-ui';
import {
  companyIdParameterName,
  companyIdStorageName,
  getPath,
  openModalParameterName,
  redirectParameterName,
  redirectUrnParameterName,
  removeStorageRedirectData,
  RouteState
} from '@router';
import { Company, companyService } from '@services';

export const companyIdStorageHandler: ActivationFnFactory = (): ActivationFn => (
  toState: State,
  fromState: State
): boolean | Promise<boolean> => {
  const {
    name,
    isLoggedIn,
    isRouted,
    pageType,
    params
  } = toState as RouteState;
  if (name === 'default' || !isLoggedIn && pageType === 'public') {
    return true;
  }
  const companyIdParameter: null | string = sessionStorage.getItem(companyIdStorageName);
  if (!companyIdParameter) {
    return true;
  }
  const companyId: number = +companyIdParameter;
  return new Promise((resolve: (value: boolean) => void, reject: (options: object) => void): void => {
    const currentCompanyId: null | number = companyService.getCurrentCompanyId();
    if (currentCompanyId === +companyId) {
      companyService.setIsCompanySelected(true);
      sessionStorage.removeItem(companyIdStorageName);
      delete toState.params[companyIdParameterName];
      return resolve(true);
    }
    companyService
      .selectCurrentCompany(companyId, true, false)
      .subscribe((company: null | Company) => {
        if (company === null || company?.id !== companyId) {
          const dataAttributesDictionary = {
            test: { 'notificationError': 'notInCompany' },
            guide: { 'notificationError': 'notInCompany' }
          };
          notificationService.error({
            namespaces: 'login',
            textTranslationKey: 'login.notInCompany',
            dataAttributesDictionary
          });
          sessionStorage.removeItem(companyIdStorageName);
          delete toState.params[companyIdParameterName];
          return resolve(true);
        }
        companyService.setIsCompanySelected(true);
        sessionStorage.removeItem(companyIdStorageName);
        delete toState.params[companyIdParameterName];
        if (isRouted) {
          removeStorageRedirectData(getPath(fromState.path));
        } else {
          removeStorageRedirectData(getPath(toState.path));
        }
        (toState as RouteState)[redirectParameterName] = true;
        toState.meta!.params[openModalParameterName] = 'productSelection';
        const redirectParameter = params[redirectUrnParameterName];
        if (!redirectParameter) {
          const name = 'default';
          return reject({ redirect: { name, params: { [openModalParameterName]: 'productSelection' } } });
        }
        return resolve(true);
      });
  });
};
