import { ActivationFn, ActivationFnFactory, State } from 'router5';
import { publicRouteNameList } from '@router';
import { consentService, permissionService, userService } from '@services';

const checkPendoReady = async (): Promise<boolean> => {
  if (typeof (window as any).pendo?.isReady === 'function') {
    return (window as any).pendo?.isReady();
  } else {
    while (typeof (window as any).pendo?.isReady !== 'function') {
      await new Promise(resolve => setTimeout(resolve, 50));
    }
    return (window as any).pendo?.isReady();
  }
};

export const consentGuard: ActivationFnFactory = (): ActivationFn => (toState: State): boolean | Promise<boolean> => {
  const { name, params } = toState;

  if (publicRouteNameList.includes(name)) {
    return true;
  }

  const {
    error$: { value: error },
    isEnabled
  } = consentService;
  const isConsentEnabled = isEnabled();

  const { user$: { value: user } } = userService;
  const isImpersonated = !!user?.impersonatedBy;
  const analyticsAllowedSelected = typeof user?.analyticsAllowed !== 'undefined';

  const analyticsPermission = permissionService.getComponentsPermission('profile').profileAnalytics;

  const isCanShowConsent =
    isConsentEnabled && // Pendo is enabled in env config
    !isImpersonated && // User is not impersonated
    !analyticsAllowedSelected && // User has not answered the consent
    !!analyticsPermission?.write && // User has permission to answer the consent
    // isInitialized && // Pendo is initialized
    // isUploaded && // Pendo is uploaded
    !error; // Pendo is not in error state

  if (name === 'consent') {
    return isCanShowConsent
      ? new Promise((resolve, reject) => checkPendoReady()
        .then(isReady => isReady
          ? resolve(true)
          : reject({
            redirect: {
              name: 'profileTab',
              params: { ...params, showConsent: true }
            }
          })
        ))
      : Promise.reject({
        redirect: {
          name: 'profileTab',
          params: { ...params, showConsent: true }
        }
      });
  } else {
    if (isCanShowConsent) {
      return new Promise((resolve, reject) => checkPendoReady()
        .then(isReady => isReady
          ? reject({ redirect: { name: 'consent' } })
          : resolve(true)
        ));
    }
  }
  return true;
};
