import { storageService } from '@mydse/design-system';
import { ApplicationProduct, getLastPageStorageName, RouteListType } from '@router';

export const getLastPageStorageValue = (key: string): null | ApplicationProduct | RouteListType => {
  const storageName = getLastPageStorageName();
  const storageData = storageService.get<Record<string, string>>(storageName);
  if (storageData === null) {
    return null;
  }
  return typeof storageData[key] === 'undefined'
    ? null
    : storageData[key];
};
