import { Observable, of, throwError } from 'rxjs';
import { Case, kebabCaseToSnakeCase } from '@mydse/utilities';
import { getAcceptedRouteTabList, ResolverData } from '@router';
import { TabsRouteList } from '@router/data';

export const routeTabHandler = <T extends string>(routeName: TabsRouteList, data: ResolverData): Observable<null | T> => {
  const routeTab = data.parameter.routeTab
    ? kebabCaseToSnakeCase(data.parameter.routeTab, Case.upper) as T
    : null;
  const acceptedRouteTabList: T[] = getAcceptedRouteTabList<T>(routeName, data.permission);
  return !routeTab || acceptedRouteTabList.includes(routeTab)
    ? of(routeTab)
    : throwError({ status: 404 });
};
