import { Observable } from 'rxjs';
import { ProductPricing } from '@mydse/typings';
import { Api } from '@services/api';

const apiUrl = '/api/survey/self-checkout';

export class SelfCheckoutApiService extends Api {

  public getSsoPrice(): Observable<ProductPricing> {
    return this.get<ProductPricing>({
      url: 'security/sso'
    });
  }

  public orderSso(expectedPrice: number): Observable<null> {
    return this.post<null>({
      url: 'security/sso',
      parameters: { expectedPrice }
    });
  }
}

export const selfCheckoutApiService = new SelfCheckoutApiService(apiUrl);
