import { Namespace } from 'react-i18next';
import { ActivationFn, ActivationFnFactory, Route } from 'router5';
import { take } from 'rxjs/operators';
import { defaultTitleConfiguration, getObjectValue, pageTitleService, TitleConfiguration } from '@mydse/design-system';
import { RouteState } from '@router';

export const pageTitleMiddleware: ActivationFnFactory = (): ActivationFn => (toState: Route): boolean | Promise<boolean> => {
  const { error, params, data } = toState as RouteState;
  if (error) {
    return true;
  }
  const { namespace, titleOptions } = data;
  const configuration: TitleConfiguration = { ...defaultTitleConfiguration, ...titleOptions };
  const parameters = {
    data: getObjectValue(data, configuration.path!, data),
    params
  };

  pageTitleService.setRoute({
    namespace: Array.isArray(namespace)
      ? namespace[0] as Namespace
      : namespace as Namespace,
    configuration,
    parameters
  });

  return new Promise((resolve: (value: true) => void) => pageTitleService
    .title$
    .pipe(take(1))
    .subscribe((pageTitle: string) => {
      (toState as RouteState).data.pageTitle = pageTitle;
      return resolve(true);
    })
  );
};
