import { Api } from '@services/api';
import { Observable } from 'rxjs';
import type { ProductData } from '@mydse/typings';

const apiUrl = '/api/survey/company/products';

class ProductApiService extends Api {
  public getProductsData(suppressDefaultErrorHandler?: boolean | number[]): Observable<ProductData> {
    return this.get<ProductData>({suppressDefaultErrorHandler});
  }
}

export const productApiService = new ProductApiService(apiUrl);
