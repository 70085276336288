import React, { ChangeEvent, FocusEvent, memo, useEffect, useRef, useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { DataFormat, Options, PlainObject, ProcessedResult, QueryOptions } from 'select2';
import { User } from '@mydse/typings';
import { classNameArrayToString, DataAttributesType, defaultDropdownPageSize, Dropdown } from '@mydse/design-system';
import { Collection, FREC, IFilter, RefType } from '@interfaces';
import { i18n } from '@services';
import { teamApiService } from '@services/api';
import { getSorter, SortDirection, useCombinedRefs } from '@utilities';
import { templateResult } from './templateResult';
import { templateSelection } from './templateSelection';
import { userFormat } from './userFormat';

import styles from './UserDropdown.styl';

const defaultFilter: IFilter[] = [];

interface Props {
  name: string;
  namespace?: string | string[];
  className?: string;
  placeholder?: string;
  data?: DataFormat[];
  addItem?: DataFormat;
  ajaxData?: (params: QueryOptions) => PlainObject;
  dataSource?: (query: Record<string, string | number>) => Observable<Collection<User>>;
  settings?: Options;
  label?: string;
  allowSelectBlockedUser?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  useFormGroup?: boolean;
  value?: string | string[] | number | number[] | null | undefined;
  onBlur?: (event: FocusEvent<HTMLSelectElement>) => void;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  onChangeObject?: (user?: User) => void;
  filters?: IFilter[];
  dataAttributesDictionary?: DataAttributesType;
}

const UserDropdown: FREC<Props, HTMLSelectElement> = forwardRef((
  props: Props,
  ref: RefType<HTMLSelectElement>
) => {
  const innerRef = useRef(null);
  const combinedRef = useCombinedRefs<HTMLSelectElement>(ref, innerRef);
  const {
    className,
    namespace,
    addItem,
    data,
    ajaxData,
    dataSource = teamApiService.getAvailableUserList.bind(teamApiService),
    placeholder,
    multiple,
    disabled,
    allowSelectBlockedUser = false,
    useFormGroup = false,
    settings = {},
    onChange,
    onChangeObject,
    filters = defaultFilter,
    dataAttributesDictionary,
    ...properties
  } = props;
  const { t } = useTranslation(namespace);
  const userList = useRef<User[]>([]);
  const [ dataValue, setDataValue ] = useState<DataFormat[]>(data || []);

  const unsubscribe$ = new Subject<void>();
  const transport = (
    ajaxSettings: JQueryAjaxSettings,
    success: any,
    failure: any
  ): Subscription => {
    unsubscribe$.next();
    const query = typeof ajaxSettings.data === 'object'
      ? ajaxSettings.data
      : {};
    return dataSource(query)
      .pipe(
        take(1),
        takeUntil(unsubscribe$),
        catchError(() => of(null))
      )
      .subscribe((response: Collection<User> | null) => {
        if (response === null) {
          failure();
        } else {
          success(response);
          userList.current = [
            ...new Set([ ...response.elements, ...userList.current ])
          ];
        }
      });
  };

  const defaultData = (params: QueryOptions): PlainObject => {
    return {
      page: params.page || 1,
      pageSize: defaultDropdownPageSize,
      sortField: 'firstName',
      sortDirection: 'asc',
      search: params.term
    };
  };

  const parameters = ajaxData ? ajaxData : defaultData;

  const processResults = (
    response: Collection<User>
  ): ProcessedResult<DataFormat> => {
    const items = response.elements.map((user: User) => ({
      id: user.id!,
      text: userFormat(user),
      disabled: !allowSelectBlockedUser && user.locked,
      blocked: user.locked
    }));

    const results = typeof addItem !== 'undefined' && response.currentPage === 1
      ? [ addItem, ...items ]
      : items;
    return {
      results,
      pagination: {
        more:
          response.currentPage <
          Math.ceil(response.totalResult / response.pageSize)
      }
    };
  };

  const sorter = getSorter({
    sortDirection: SortDirection.none,
    ...(addItem ? { topItemList: [ '' + addItem.id ] } : {})
  });

  const getSettings = (): Options => ({
    minimumInputLength: 0,
    minimumResultsForSearch: 6,
    allowClear: true,
    disabled: disabled || false,
    multiple: multiple || false,
    placeholder: placeholder ? t(placeholder) : t('placeholder'),
    sorter,
    templateSelection: templateSelection(t('common:inactiveUser')),
    ajax: {
      delay: 250,
      cache: true,
      transport,
      data: parameters,
      processResults
    },
    ...settings
  });

  const [ dropdownSettings, setDropdownSettings ] = useState<Options>(getSettings());

  useEffect(() => {
    return () => {
      unsubscribe$.next();
      unsubscribe$.complete();
    };
  }, []);

  useEffect(() => {
    setDropdownSettings(getSettings());
  }, [ i18n.language, ajaxData, filters ]);

  useEffect(() => {
    setDataValue(data || []);
  }, [ data ]);

  const classNameString = classNameArrayToString([
    className,
    styles.userSelect
  ])

  if (typeof addItem !== 'undefined') {
    if (
      Array.isArray(data) &&
      !data.find((item: DataFormat) => item.id === addItem.id)
    ) {
      data.unshift(addItem);
    }
  }

  const changeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    if (typeof onChange !== 'undefined') {
      onChange(event);
    }
    if (typeof onChangeObject !== 'undefined') {
      const id = +event.target.value;
      const user = userList.current.find((item: User) => item.id === id);
      onChangeObject(user);
    }
  };

  return (
    <Dropdown
      ref={ combinedRef }
      className={ classNameString }
      settings={ dropdownSettings }
      data={ dataValue }
      language={ i18n.language }
      dataAttributesDictionary={ dataAttributesDictionary }
      useFormGroup={ useFormGroup }
      useTemplateResult={ templateResult(
        t('common:inactiveUser'),
        t('common:blocked'),
        t('common:blockedTooltip')
      ) }
      onChange={ changeHandler }
      { ...properties }
    />
  );
});

export default memo(UserDropdown);
