import { storageService } from '@mydse/design-system';
import { redirectUrnStorageName } from '@router';

export const setStorageRedirectData = (key: string, value: string): void => {
  const storageData = storageService.get<Record<string, string>>(redirectUrnStorageName);
  if (storageData !== null) {
    storageService.set(redirectUrnStorageName, { ...storageData, [key]: value });
  } else {
    storageService.set(redirectUrnStorageName, { [key]: value });
  }
};
