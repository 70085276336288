import { ActivationFn, ActivationFnFactory, State } from 'router5';
import {
  getProductStorageValue,
  removeProductStorageValue,
  getStorageRedirectData,
  isApplicationProduct,
  PageType,
  productRedirect,
  productStorageName,
  removeStorageRedirectData,
  RouteState
} from '@router';

export const redirectStorageHandler: ActivationFnFactory = (): ActivationFn => (toState: State): boolean | Promise<boolean> => {
  const { name, isLoggedIn, isRouted, pageType } = toState as RouteState;
  if (!isRouted || !isLoggedIn || pageType === PageType.public || name === 'consent') {
    return true;
  }
  const product = getProductStorageValue();
  if (isApplicationProduct(product)) {
    const route = getStorageRedirectData(productStorageName);

    removeStorageRedirectData(productStorageName);
    removeProductStorageValue();

    return productRedirect({ product, route });
  }
  const redirectUrn = getStorageRedirectData(name);
  if (redirectUrn) {
    removeStorageRedirectData(name);
    return Promise.reject({ redirect: { name: redirectUrn }});
  }
  return true;
};
