import React, { FC, HTMLAttributes, memo, ReactElement } from 'react';
import { NewsItem as NewsItemInterface } from '@services';
import { classNameArrayToString, Ellipsis, Locale, Scrollbar } from '@mydse/design-system';

import styles from './NewsItem.styl';

export type Preset = 'vertical' | 'horizontal';

interface OwnProps {
  locale: Locale;
  item: NewsItemInterface;
  preset?: Preset;
}

type Props = OwnProps & HTMLAttributes<HTMLDivElement>;

const NewsItem: FC<Props> = ({ item, locale, preset = 'vertical', className }): ReactElement => preset === 'vertical'
  ? (
    <Scrollbar className={ styles.scroll } useFlex={ true } usePadding={ false }>
      <div className={ classNameArrayToString([ styles.item, className ]) }>
        <h2 className={ styles.title }>{ item.title[locale] }</h2>
        <div className={ styles.imageWrap }>
          <img
            className={ styles.image }
            src={ `/api/survey/public-content/${ item.mediaFile[locale] }` }
            alt={ item.title[locale] }
            onError={ ({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '';
              currentTarget.classList.add(styles.error);
            } }
          />
        </div>
        <p
          className={ styles.text }
          dangerouslySetInnerHTML={ { __html: item.description[locale] } }
        />
      </div>
    </Scrollbar>
  )
  : (
    <div className={ classNameArrayToString([ styles.item, styles.horizontal, className ]) }>
      <Ellipsis
        className={ styles.title }
        elementType="h2"
        singleLine={ true }
      >
        { item.title[locale] }
      </Ellipsis>
      <div className={ styles.main }>
        <div className={ styles.imageWrap }>
          <img
            className={ styles.image }
            src={ `/api/survey/public-content/${ item.mediaFile[locale] }` }
            alt={ item.title[locale] }
            onError={ ({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '';
              currentTarget.classList.add(styles.error);
            } }
          />
        </div>
        <Scrollbar
          className={ styles.scroll }
          useFlex={ true }
          usePadding={ false }
          scrollYOffset={ {
            top: 0,
            bottom: 0
          } }
        >
          <p
            className={ styles.text }
            dangerouslySetInnerHTML={ { __html: item.description[locale] } }
          />
        </Scrollbar>
      </div>
    </div>
  );

export default memo(NewsItem);
