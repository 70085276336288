import React, { FC, memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  ButtonProperties,
  classNameArrayToString,
  SVG
} from '@mydse/design-system';
import {
  modalService,
  useDataAttributes
} from '@mydse/react-ui';
import { getStaticProductLogoResourceUrl } from '@mydse/utilities';
import { FullRouteListType, redirect, defaultRoute } from '@router';
import { Company, companyService } from '@services';
import { useProductSwitcherModalOptions } from '@shared/components';

import {
  faRepeat,
  faHomeAlt,
  faPoll,
  faExclamationCircle,
  faGraduationCap,
  faBooks,
  faUser
} from '@fortawesome/pro-light-svg-icons';
import { faGrid3 } from '@fortawesome/pro-solid-svg-icons';
import styles from './ProductSwitcherButton.styl';
import { useRoute } from 'react-router5';

export const menuIconDictionary: Partial<Record<FullRouteListType, IconDefinition>> = {
  onboarding: faHomeAlt,
  dashboard: faHomeAlt,
  risk: faPoll,
  dataBreach: faExclamationCircle,
  courseManagement: faBooks,
  myCourses: faGraduationCap,
  profile: faUser
};

interface OwnProps {
  currentCompany: null | Company;
  isMultiProduct: null | boolean;
  isMultitenancy: null | boolean;
  isModalActionButton?: boolean;
}

type Props = OwnProps & Omit<ButtonProperties, 'text' | 'disabled' | 'preset' | 'onClick' | 'className'>;

const ProductSwitcherButton: FC<Props> = ({
  currentCompany,
  isMultiProduct,
  isMultitenancy,
  isModalActionButton = false,
  ...properties
}): null | ReactElement => {
  const { t } = useTranslation('products');

  const { router } = useRoute();
  const currentRouteName = router.getState().name;
  const defaultRouteName = defaultRoute();
  const isDefaultRoute = currentRouteName === defaultRouteName;
  const isProductsRoute = currentRouteName === 'products';
  const isCompanySelected = companyService.getIsCompanySelected();

  const { modalOptions } = useProductSwitcherModalOptions({
    id: 'productSwitcherModal',
    currentCompany,
    isMultiProduct,
    isMultitenancy
  });

  const clickHandler = (): void => {
    if (!isCompanySelected) {
      return;
    }
    if (isProductsRoute) {
      return;
    }
    if (!isMultiProduct) {
      if (!isDefaultRoute) {
        redirect({ route: defaultRouteName });
      }
      return;
    }
    modalService.show(modalOptions);
  };

  const image = getStaticProductLogoResourceUrl({ name: 'proliance360.svg' });
  const buttonContent = (
    <>
      <SVG className={ styles.logo } src={ image }/>
      { isMultiProduct && <FontAwesomeIcon className={ styles.icon } icon={ faRepeat }/> }
    </>
  );

  const title: undefined | string = isMultiProduct
    ? isProductsRoute
      ? undefined
      : t('common:products.title.select')
    : isDefaultRoute
      ? undefined
      : t('common:products.navigate', { route: defaultRouteName });

  const button = (
    <Button
      { ...properties }
      className={ styles.modalButton }
      preset="default"
      text={ buttonContent }
      title={ title }
      useEllipsis={ false }
      disabled={ !isMultiProduct }
      onClick={ clickHandler }
      dataAttributesDictionary={ {
        test: { toggleModal: 'product-switcher' },
        guide: { toggleModal: 'product-switcher' }
      } }
    />
  );

  const buttonClassName = classNameArrayToString([
    styles.button,
    ...[ (!isCompanySelected || !isMultiProduct && isDefaultRoute || isProductsRoute) && styles.disabled ]
  ]);

  const routeIcon = !isMultiProduct && !isDefaultRoute && menuIconDictionary[defaultRouteName];
  const icon: IconDefinition = !isMultiProduct && routeIcon
    ? routeIcon
    : faGrid3;

  const logoButtonIcon = isCompanySelected && isMultiProduct
    ? (
      <FontAwesomeIcon
        className={ styles.logoButtonIcon }
        icon={ icon }
      />
    )
    : null;

  const dataAttributes = useDataAttributes({
    test: { productSwitcher: isMultiProduct ? 'modal' : 'navigate' },
    guide: { productSwitcher: isMultiProduct ? 'modal' : 'navigate' }
  });

  const iconButton = (
    <div
      className={ buttonClassName }
      title={ title }
      { ...dataAttributes }
      onClick={ clickHandler }
    >
      { logoButtonIcon }
      <SVG
        className={ styles.logoButton }
        src={ image }
      />
    </div>
  );

  return isModalActionButton
    ? button
    : iconButton;
};

export default memo(ProductSwitcherButton);
