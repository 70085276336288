import { ButtonSize, classNameArrayToString } from '@mydse/design-system';
import React, { ChangeEvent, FC, memo, ReactElement } from 'react';

import styles from './ToggleButton.styl';

export interface IToggleButtonData {
  text: string;
  value: any;
}

interface IOwnProps {
  prefix?: string;
  name?: string;
  label?: string;
  className?: string;
  useFormGroup?: boolean;
  data: IToggleButtonData[];
  value?: any;
  size?: ButtonSize;
  disabled?: boolean;
  onChange: (value: string) => void;
}

type Props = IOwnProps;
const ToggleButton: FC<Props> = ({
  prefix,
  name = 'button',
  label,
  useFormGroup = false,
  size,
  data,
  disabled,
  value,
  onChange,
  ...props
}) => {
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const buttonList = data.map(
    (item: IToggleButtonData, index: number): ReactElement => {
      const checked = value === item.value || !value && !item.value;
      const key = typeof prefix === 'undefined'
        ? `${ name }-${ index }`
        : `${ prefix }-${ name }-${ index }`;
      const className = classNameArrayToString([
        styles.label,
        size && styles[size],
        checked ? styles.primary : styles.default,
        disabled && styles.disabled
      ]);
      return (
        <li className={ styles.listItem } key={ key }>
          <input
            className={ styles.input }
            type="radio"
            name={ name }
            id={ key }
            disabled={ disabled }
            checked={ checked }
            onChange={ changeHandler }
            value={ item.value }
          />
          <label className={ className } htmlFor={ key }>
            { item.text }
          </label>
        </li>
      );
    }
  );

  const labelElement = label ? (
    <label className={ styles.formLabel }>{ label }</label>
  ) : null;

  const className = classNameArrayToString([
    props.className,
    useFormGroup
      ? [ styles.formGroup ]
      : [ styles.noFormGroup ]
  ]);

  return (
    <div className={ className }>
      { labelElement }
      <ul className={ styles.list }>{ buttonList }</ul>
    </div>
  );
};

export default memo(ToggleButton);
