import { Params } from 'router5/dist/types/base';
import type { ProductType } from '@mydse/typings';
import { notificationService } from '@mydse/react-ui';
import { getProductUrl } from '@mydse/utilities';
import { productService } from '@services';
import { FullRouteListType } from '../../configuration';

export interface ProductRedirectOptions {
  product?: ProductType;
  route?: null | FullRouteListType | string;
  redirectUrn?: null | FullRouteListType | string;
  parameters?: Params;
}

export const productRedirect = (options: ProductRedirectOptions): boolean => {
  const {
    route,
    redirectUrn,
    product,
    parameters
  } = options;
  const href = getProductUrl({
    product,
    route: route
      ? route.toString()
      : undefined,
    redirectUrn: redirectUrn
      ? redirectUrn.toString()
      : undefined,
    productData: productService.getProducts(),
    query: parameters
      ? Object.keys(parameters)
        .reduce((result: Record<string, string>, key: string) => {
          const value = parameters[key];
          if (typeof value !== 'undefined' && value !== null) {
            result[key] = value.toString();
          }
          return result;
        }, {})
      : parameters
  });
  console.log('href', href);
  if (href) {
    window.location.href = href;
    return false
  }
  const dataAttributesDictionary = {
    test: { notificationError: 'accessDenied' },
    guide: { notificationError: 'accessDenied' }
  };
  notificationService.error({
    titleTranslationKey: 'error.accessDenied.title',
    textTranslationKey: 'error.accessDenied.text',
    dataAttributesDictionary
  });
  return true;
};
