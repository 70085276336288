import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input } from '@mydse/design-system';
import { InviteAcademyUserData, InviteCompanyManagementData, TeamMemberData } from '@services';
import { ErrorFactory } from '@shared/form';

interface IOwnProps {
  show: boolean;
  form: UseFormReturn<TeamMemberData>
    | UseFormReturn<InviteCompanyManagementData>
    | UseFormReturn<InviteAcademyUserData>;
}

type Data = TeamMemberData | InviteCompanyManagementData;
type Props = IOwnProps;

const InviteUserForm: FC<Props> = ({
  show,
  form
}): null | ReactElement => {
  const { t } = useTranslation('form');

  const {
    register,
    formState: {
      errors
    }
  } = form as UseFormReturn<Data>;

  const emailInput = (
    <div className="inputWrap">
      <Input
        label={t('email')}
        useFormGroup={false}
        dataAttributesDictionary={{
          test: { 'invite-user': 'email' },
          guide: { 'invite-user': 'email' }
        }}
        {...register('email')}
      />
      <ErrorMessage
        errors={errors}
        name="email"
        render={ErrorFactory(t, t('email'))}
      />
    </div>
  );

  const firstNameInput = (
    <div className="column">
      <div className="inputWrap">
        <Input
          label={t('firstName')}
          useFormGroup={false}
          dataAttributesDictionary={{
            test: { 'invite-user': 'firstName' },
            guide: { 'invite-user': 'firstName' }
          }}
          {...register('firstName')}
        />
        <ErrorMessage
          errors={errors}
          name="firstName"
          render={ErrorFactory(t, t('firstName'))}
        />
      </div>
    </div>
  );

  const lastNameInput = (
    <div className="column">
      <div className="inputWrap">
        <Input
          label={t('lastName')}
          useFormGroup={false}
          dataAttributesDictionary={{
            test: { 'invite-user': 'lastName' },
            guide: { 'invite-user': 'lastName' }
          }}
          {...register('lastName')}
        />
        <ErrorMessage
          errors={errors}
          name="lastName"
          render={ErrorFactory(t, t('lastName'))}
        />
      </div>
    </div>
  );

  if (!show) {
    return null;
  }

  return (
    <>
      <div className="row">
        {emailInput}
      </div>
      <div className="row">
        {firstNameInput}
        {lastNameInput}
      </div>
    </>
  );
};

export default InviteUserForm;
