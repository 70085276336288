import React, { FC, HTMLAttributes, memo, ReactElement, useState } from 'react';
import { NewsItem } from '@services';
import { classNameArrayToString } from '@mydse/design-system';
import { View, Dots } from './components';

import styles from './Carousel.styl';

interface OwnProps {
  titleList?: string[];
  dotsClassName?: string;
  autoRotationTimeout?: number;
  newsList: NewsItem[];
  children: ReactElement[];
}

type Props = OwnProps & HTMLAttributes<HTMLDivElement>;

const Carousel: FC<Props> = ({
  titleList,
  newsList,
  className,
  dotsClassName,
  autoRotationTimeout,
  children
}): ReactElement => {
  const newsListLength = newsList.length;
  const [ currentSlide, setCurrentSlide ] = useState(0);

  const classNameString = classNameArrayToString([
    styles.wrap,
    className
  ]);

  return (
    <div className={ classNameString }>
      <div className={ styles.content }>
        <View current={ currentSlide }>
          { children }
        </View>
      </div>
      <Dots
        className={ classNameArrayToString([ styles.dots, dotsClassName ]) }
        count={ newsListLength }
        current={ currentSlide }
        setCurrent={ setCurrentSlide }
        titleList={ titleList }
        autoRotationTimeout={ autoRotationTimeout }
      />
    </div>
  );
};

export default memo(Carousel);
