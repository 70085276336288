import { Observable } from 'rxjs';
import { SsoProvider, TokenResponse } from '@mydse/typings';
import { router } from '@router';
import { AuthenticationService, SsoData } from '@services';
import { authenticationApiService } from '@services/api';

const env = (window as any).env || {};
const ssoDictionary: Record<SsoProvider, SsoData> = {
  GOOGLE: {
    url: env.googleUrl as string,
    clientId: env.googleClientId as string,
    scope: env.googleScope as string
  },
  ENTRA: {
    url: env.entraUrl as string,
    clientId: env.entraClientId as string,
    scope: env.entraScope as string
  }
};
const getRedirectUri = (ssoProvider: SsoProvider): string => {
  const urlData = new URL(window.location.href);
  const { protocol, host } = urlData;
  const path = router().buildPath('sso', { ssoProvider: ssoProvider.toLowerCase() });
  urlData.pathname = path;
  return `${protocol}//${host}${path}`;
};

const getState = (email: null | string): null | string => {
  return email
  ? btoa(encodeURIComponent(email))
  : null;
};

const getSsoUrl = (ssoProvider: SsoProvider, email: null | string): string => {
  const data = ssoDictionary[ssoProvider];
  const providerUrl: string = data.url;
  const clientId: string = data.clientId;
  const scope: string = data.scope;
  const responseType: string = 'code';
  const responseMode: string = 'query';
  const redirectUri = getRedirectUri(ssoProvider);
  const state = getState(email);
  const url = new URL(providerUrl);
  url.searchParams.set('client_id', clientId);
  url.searchParams.set('scope', scope);
  url.searchParams.set('response_type', responseType);
  url.searchParams.set('response_mode', responseMode);
  url.searchParams.set('redirect_uri', redirectUri);
  if (state) {
    url.searchParams.set('state', state);
  }
  if (email) {
    url.searchParams.set('login_hint', email);
  }
  return url.toString();
};

const ssoLogin = (ssoProvider: SsoProvider, parameters: Record<string, string>): Observable<TokenResponse> => authenticationApiService
  .ssoLogin(ssoProvider, parameters);

export const authenticationService: AuthenticationService = {
  getLoginOptions: authenticationApiService.getLoginOptions.bind(authenticationApiService),
  login: authenticationApiService.login.bind(authenticationApiService),
  getRedirectUri,
  getSsoUrl,
  ssoLogin,
  tfaResend: authenticationApiService.tfaResend.bind(authenticationApiService),
  tfaVerification: authenticationApiService.tfaVerification.bind(authenticationApiService),
  logout: authenticationApiService.logout.bind(authenticationApiService),
};
