import { from, Observable } from 'rxjs';
import { tokenService } from '@mydse/design-system';
import { Api } from '@services/api';

const apiUrl = 'api/survey/email';

export class EmailApiService extends Api {
  public customerRequest(body: FormData): Observable<Response> {
    return from(
      fetch(
        `/${ apiUrl }/customer-request`,
        {
          method: 'POST',
          headers: { Authorization: `Bearer ${ tokenService.getToken() }` },
          body
        }
      )
    );
  }
}

export const emailApiService = new EmailApiService(apiUrl);
